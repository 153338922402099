import * as React from 'react';
import type { SchedulingShift } from '@bondvet/types/scheduling';
import { gql } from '@apollo/client';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import useTimezone from 'hooks/useTimezone';
import useVetspireQuery from 'hooks/useVetspireQuery';
import {
    isAfter,
    format,
    endOfMonth,
    isSameDay,
    parse,
    toDate,
    isSameMonth,
    isWithinInterval,
    setDay,
    addDays,
    addMinutes,
    getDay,
    getDate,
    addMonths,
    differenceInMilliseconds,
} from 'date-fns';
import { getTimezoneOffset, formatInTimeZone } from 'date-fns-tz';
import useLocationId from 'hooks/useLocationId';
import useViewerSettings from 'hooks/useViewerSettings';
import { ProviderRightValue } from '@bondvet/types/providers';
import useExpandedAccessSlots, {
    type ExpandedAccessSlots,
} from './useExpandedAccessSlots';
import {
    type Capacities,
    DATA_TIME_FORMAT,
    DATE_FORMAT,
    DISPLAY_TIME_FORMAT,
    type Appointment,
    type BookedAppointments,
    type ProviderSlot,
    type ProviderAndSlots,
    type UpdateExpandedAccessSlots,
} from '../types';

type LocationSlot = {
    time: string;
    label: string;
};

const DAYS_OF_THE_WEEK = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const;

type DayOfTheWeek = (typeof DAYS_OF_THE_WEEK)[number];

type LocationSlots = Partial<Record<DayOfTheWeek, readonly LocationSlot[]>>;

const shiftsQuery = gql`
    query shiftsQuery(
        $vetspireLocationId: ID!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        dbShifts(
            vetspireLocationIds: [$vetspireLocationId]
            fromDate: $fromDate
            toDate: $toDate
            onlyPublishedVetspireShifts: true
        ) {
            id: _id
            date
            vetspireProviderId
        }
    }
`;

const providersQuery = gql`
    query providers($ids: [ID!]!) {
        providers(ids: $ids) {
            id
            name
            givenName
            familyName
        }
    }
`;

type ProvidersQueryResult = {
    providers: null | readonly ProviderScheduleProvider[];
};

type ProvidersQueryVariables = {
    ids: string[];
};

type Shift = Pick<SchedulingShift, 'date' | 'vetspireProviderId'> & {
    id: SchedulingShift['_id'];
};

type ShitfsQueryResult = {
    dbShifts: null | readonly Shift[];
};

type ShitfsQueryVariables = {
    vetspireLocationId: string;
    fromDate: string;
    toDate: string;
};

function getStartOfDayInTimezone(dateString: string, timezone: string): Date {
    // let's construct a UTC date that's on the first day of the month
    // but set the hours to 13, so we're definitely on the same date already
    // in the target timezone.
    // this will work until we open clinics in timezones with UTC+11 or more
    const match = dateString.match(/^(\d{4})-(\d{2})(?:-(\d{2}))?/);

    if (!match) {
        throw new Error(`invalid date: "${dateString}"`);
    }

    const [, yearString, monthString, dayString] = match;
    const utcStart = new Date(
        Date.UTC(
            parseInt(yearString, 10),
            parseInt(monthString, 10) - 1,
            parseInt(dayString ?? '1', 10),
            13,
        ),
    );

    // now get the timezone offset in milliseconds for that date in the target timezone
    const offsetMs = getTimezoneOffset(timezone, utcStart);

    // set the UTC hour to 0
    utcStart.setUTCHours(0);

    // construct a new Date that represents the start of the month in the target timezone.
    // remember that the offset has to be _subtracted_
    return new Date(utcStart.getTime() - offsetMs);
}

const locationSettingsQuery = gql`
    query locationSettings($locationId: ID!) {
        location(id: $locationId) {
            appointmentSlotMinutes
            locationHours {
                monday: mondayRanges
                tuesday: tuesdayRanges
                wednesday: wednesdayRanges
                thursday: thursdayRanges
                friday: fridayRanges
                saturday: saturdayRanges
                sunday: sundayRanges
            }
            timezone
        }
    }
`;

const appointmentsQuery = gql`
    query locationAppointments(
        $locationId: ID!
        $start: DateTime!
        $end: DateTime!
    ) {
        appointments(locationId: $locationId, start: $start, end: $end) {
            id
            start
            patient {
                name
                client {
                    name
                }
            }
            providerId
        }
    }
`;

type ProviderScheduleProvider = {
    id: string;
    name: string | null;
    givenName: string | null;
    familyName: string | null;
};

type LocationHours = Partial<Record<DayOfTheWeek, readonly [number, number][]>>;

type LocationSettings = {
    id: string;
    appointmentSlotMinutes: number;
    locationHours: null | LocationHours;
    timezone: string | null;
};

type LocationSettingsQueryResult = {
    location: null | LocationSettings;
};

type LocationSettingsQueryVariables = {
    locationId: string;
};

type AppointmentsQueryResult = {
    appointments: null | readonly Appointment[];
};

type AppointmentsQueryVariables = {
    locationId: string;
    start: string;
    end: string;
};

function createIntradayCapacityData(
    month: string,
    allProviders: null | Record<string, ProviderScheduleProvider>,
    shifts: null | readonly Shift[],
    isAdmin: boolean,
    viewerId: string,
    locationSlots: LocationSlots | null,
    expandedAccessSlots: ExpandedAccessSlots,
    locationTimezone: string,
): Capacities {
    if (!shifts || !allProviders) {
        return {};
    }
    const start = parse(`${month}-01`, DATE_FORMAT, new Date());
    let currentDate = toDate(start);
    const result: Capacities = {};
    const today = new Date();

    const providersMap: Record<string, ProviderScheduleProvider[]> = {};

    for (const shift of shifts) {
        const { date, vetspireProviderId } = shift;

        if (!(date in providersMap)) {
            providersMap[date] = [];
        }

        if (
            vetspireProviderId &&
            (isAdmin || vetspireProviderId === viewerId) &&
            allProviders[vetspireProviderId]
        ) {
            providersMap[date].push(allProviders[vetspireProviderId]);
        }
    }

    while (isSameMonth(currentDate, start)) {
        const isFuture = isAfter(currentDate, today);
        const currentDateString = format(currentDate, DATE_FORMAT);
        const currentDateInLocationTimezone = getStartOfDayInTimezone(
            currentDateString,
            locationTimezone,
        );

        const dayOfTheWeekIdx = getDay(currentDate);

        const locationSlotsOnThisDay = structuredClone(
            locationSlots?.[DAYS_OF_THE_WEEK[dayOfTheWeekIdx]] ?? [],
        );

        const providers: ProviderAndSlots[] = [];

        if (currentDateString in providersMap) {
            for (const { id, name, givenName, familyName } of providersMap[
                currentDateString
            ]) {
                const selectedSlots =
                    expandedAccessSlots[[currentDateString, id].join('|')]
                        ?.availableSlots ?? [];

                const slots: ProviderSlot[] = [];

                for (const { time, ...slot } of locationSlotsOnThisDay) {
                    const slotDate = parse(
                        [currentDateString, time].join(' '),
                        [DATE_FORMAT, DATA_TIME_FORMAT].join(' '),
                        new Date(),
                    );

                    const differenceMs = differenceInMilliseconds(
                        slotDate,
                        currentDate,
                    );

                    const slotTime = new Date(
                        currentDateInLocationTimezone.getTime() + differenceMs,
                    );

                    const timeString = slotTime.toISOString();
                    const key = [id, timeString].join('|');

                    slots.push({
                        ...slot,
                        availableForExtendedAccess:
                            selectedSlots.includes(timeString),
                        time: timeString,
                        key,
                    });
                }

                providers.push({
                    id,
                    name: name || [givenName, familyName].join(' '),
                    slots,
                    numberOfAvailableForExtendedAccess: slots.filter(
                        (slot) => slot.availableForExtendedAccess,
                    ).length,
                });
            }
        }

        result[currentDateString] = {
            label: getDate(currentDate).toString(10),
            date: currentDateString,
            providers,
            isFuture,
            isToday: isSameDay(currentDate, today),
        };

        currentDate = addDays(currentDate, 1);
    }

    return result;
}

type UseIntradayCapacity = {
    capacities: Capacities;
    appointments: BookedAppointments;
    isAdmin: boolean;
    isLoading: boolean;
    viewerName: string | null;
    updateExpandedAccessSlots: UpdateExpandedAccessSlots;
};

export default function useIntradayCapacity(
    month: string,
): UseIntradayCapacity {
    const { rights, viewer } = useViewerSettings();
    const locationId = useLocationId();
    const userTimezone = useTimezone();
    const { expandedAccessSlots, updateExpandedAccessSlots } =
        useExpandedAccessSlots(locationId, month);

    const shiftsQueryVariables = React.useMemo<ShitfsQueryVariables>(() => {
        const start = parse(`${month}-01`, DATE_FORMAT, new Date());
        const end = endOfMonth(start);

        return {
            vetspireLocationId: locationId,
            fromDate: format(start, DATE_FORMAT),
            toDate: format(end, DATE_FORMAT),
        };
    }, [locationId, month]);

    const { data: shiftsData, loading: loadingShifts } = useSchedulingQuery<
        ShitfsQueryResult,
        ShitfsQueryVariables
    >(shiftsQuery, {
        variables: shiftsQueryVariables,
        fetchPolicy: 'cache-and-network',
    });

    const providersQueryVariables =
        React.useMemo<null | ProvidersQueryVariables>(() => {
            if (shiftsData?.dbShifts) {
                const providerIds: string[] = [];

                for (const { vetspireProviderId } of shiftsData.dbShifts) {
                    if (!providerIds.includes(vetspireProviderId)) {
                        providerIds.push(vetspireProviderId);
                    }
                }

                return { ids: providerIds };
            }

            return null;
        }, [shiftsData?.dbShifts]);

    const { data: providersData, loading: loadingProviders } = useVetspireQuery<
        ProvidersQueryResult,
        ProvidersQueryVariables
    >(providersQuery, {
        variables: providersQueryVariables as ProvidersQueryVariables,
        skip: providersQueryVariables === null,
        fetchPolicy: 'cache-and-network',
    });

    const providers = React.useMemo<null | Record<
        string,
        ProviderScheduleProvider
    >>(() => {
        if (providersData?.providers) {
            const newProviders: Record<string, ProviderScheduleProvider> = {};

            for (const provider of providersData.providers) {
                newProviders[provider.id] = provider;
            }

            return newProviders;
        }

        return null;
    }, [providersData?.providers]);

    const { data: settingsData } = useVetspireQuery<
        LocationSettingsQueryResult,
        LocationSettingsQueryVariables
    >(locationSettingsQuery, {
        variables: { locationId },
        skip: !locationId,
        fetchPolicy: 'cache-and-network',
    });

    const timezone = React.useMemo(() => {
        return settingsData?.location?.timezone ?? userTimezone;
    }, [userTimezone, settingsData?.location?.timezone]);

    const appointmentsQueryVariables =
        React.useMemo<null | AppointmentsQueryVariables>(() => {
            if (!locationId) {
                return null;
            }

            if (!timezone) {
                return null;
            }

            const start = getStartOfDayInTimezone(month, timezone);

            // for the end of the month, let's add 1 full month and then subtract 1ms
            const end = new Date(addMonths(start, 1).getTime() - 1);

            return {
                locationId,
                start: start.toISOString(),
                end: end.toISOString(),
            };
        }, [locationId, month, timezone]);

    const { data: appointmentsData } = useVetspireQuery<
        AppointmentsQueryResult,
        AppointmentsQueryVariables
    >(appointmentsQuery, {
        skip: appointmentsQueryVariables === null,
        variables: appointmentsQueryVariables as AppointmentsQueryVariables,
        fetchPolicy: 'cache-and-network',
    });

    const appointments = React.useMemo<BookedAppointments>(() => {
        const loadedAppointments = appointmentsData?.appointments;

        if (!loadedAppointments?.length) {
            return {};
        }

        const result: Record<string, Appointment[]> = {};

        for (const { start: startString, ...rest } of loadedAppointments) {
            const { providerId } = rest;
            // make sure start is actually a proper ISO8601 string
            const start = new Date(startString).toISOString();

            const appointment = {
                ...rest,
                start,
            };

            const key = [providerId, start].join('|');

            if (key in result) {
                result[key].push(appointment);
            } else {
                result[key] = [appointment];
            }
        }

        return result;
    }, [appointmentsData?.appointments]);

    const locationSlots = React.useMemo<null | LocationSlots>(() => {
        if (
            !settingsData?.location?.locationHours ||
            !settingsData?.location?.appointmentSlotMinutes ||
            !timezone
        ) {
            return null;
        }

        const { locationHours, appointmentSlotMinutes } = settingsData.location;

        const slots: LocationSlots = {};

        for (let i = 0; i < DAYS_OF_THE_WEEK.length; i += 1) {
            const dayOfTheWeek = DAYS_OF_THE_WEEK[i];

            if (locationHours[dayOfTheWeek]) {
                const date = setDay(
                    getStartOfDayInTimezone(month, timezone),
                    i,
                );
                const ranges = locationHours[dayOfTheWeek]?.slice(0) ?? [];

                const daySlots: LocationSlot[] = [];

                while (ranges.length > 0) {
                    const [startMinutes, endMinutes] = ranges.shift() as [
                        number,
                        number,
                    ];

                    const startTime = addMinutes(date, startMinutes);
                    const endTime = addMinutes(date, endMinutes);

                    let time = toDate(startTime);

                    while (
                        isWithinInterval(time, {
                            start: startTime,
                            end: endTime,
                        })
                    ) {
                        daySlots.push({
                            label: formatInTimeZone(
                                time,
                                timezone,
                                DISPLAY_TIME_FORMAT,
                            ),
                            time: formatInTimeZone(
                                time,
                                timezone,
                                DATA_TIME_FORMAT,
                            ),
                        });

                        time = addMinutes(time, appointmentSlotMinutes ?? 30);
                    }
                }

                if (daySlots.length > 0) {
                    slots[dayOfTheWeek] = daySlots;
                }
            }
        }

        return slots;
    }, [settingsData?.location, month, timezone]);

    const isAdmin =
        ProviderRightValue.enabled_allRecords ===
        rights?.vetspireExtension_intradayCapacity;

    return {
        capacities: React.useMemo(
            () =>
                createIntradayCapacityData(
                    month,
                    providers,
                    shiftsData?.dbShifts ?? null,
                    isAdmin,
                    viewer?.id ?? '',
                    locationSlots,
                    expandedAccessSlots,
                    timezone,
                ),
            [
                month,
                isAdmin,
                viewer?.id,
                locationSlots,
                timezone,
                providers,
                shiftsData?.dbShifts,
                expandedAccessSlots,
            ],
        ),
        appointments,
        isAdmin,
        viewerName: viewer?.name ?? null,
        updateExpandedAccessSlots,
        isLoading: loadingShifts || loadingProviders,
    };
}
