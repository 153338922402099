import * as React from 'react';
import type { ExecutableDefinitionNode } from 'graphql/language/ast';
import { gql } from '@apollo/client';
import type {
    ExpandedAccessSlot,
    GetExpandedAccessSlotsArguments,
    UpdateExpandedAccessSlotsArguments,
} from '@bondvet/types/intradayCapacity';
import { endOfMonth, parse, format } from 'date-fns';
import useSchedulingQuery from 'hooks/useSchedulingQuery';
import useSchedulingMutation from 'hooks/useSchedulingMutation';
import type { OperationResult } from '@bondvet/types';
import { DATE_FORMAT, type UpdateExpandedAccessSlots } from '../types';

const expandedAccessSlotsQuery = gql`
    query expandedAccessSlotsQuery(
        $vetspireLocationId: ID!
        $fromDate: DateString!
        $toDate: DateString!
    ) {
        expandedAccessSlots(
            vetspireLocationId: $vetspireLocationId
            fromDate: $fromDate
            toDate: $toDate
        ) {
            _id
            date
            providerId
            availableSlots
            provider {
                id
                name
            }
        }
    }
`;

const updateExpandedAccessSlotsMutation = gql`
    mutation updateExpandedAccessSlotsMutation(
        $vetspireLocationId: ID!
        $date: DateString!
        $providerId: String!
        $availableSlots: [String!]!
    ) {
        updateExpandedAccessSlots(
            vetspireLocationId: $vetspireLocationId
            date: $date
            providerId: $providerId
            availableSlots: $availableSlots
        ) {
            success
        }
    }
`;

type ExpandedAccessSlotsQueryResult = {
    expandedAccessSlots: readonly ExpandedAccessSlot[];
};

type UpdateExpandedAccessSlotsMutationResult = {
    updateExpandedAccessSlotsMutation: OperationResult;
};

// the key is the date and the provider's ID, joined by '|'
export type ExpandedAccessSlots = Record<string, ExpandedAccessSlot>;

type UseExpandedAccessSlots = {
    expandedAccessSlots: ExpandedAccessSlots;
    updateExpandedAccessSlots: UpdateExpandedAccessSlots;
    loading: boolean;
};

export default function useExpandedAccessSlots(
    vetspireLocationId: string,
    month: string,
): UseExpandedAccessSlots {
    const start = parse(`${month}-01`, DATE_FORMAT, new Date());
    const end = endOfMonth(start);

    const { data, loading } = useSchedulingQuery<
        ExpandedAccessSlotsQueryResult,
        GetExpandedAccessSlotsArguments
    >(expandedAccessSlotsQuery, {
        variables: {
            vetspireLocationId,
            fromDate: format(start, DATE_FORMAT),
            toDate: format(end, DATE_FORMAT),
        },
        fetchPolicy: 'cache-and-network',
    });

    const [update, { loading: updating }] = useSchedulingMutation<
        UpdateExpandedAccessSlotsMutationResult,
        UpdateExpandedAccessSlotsArguments
    >(updateExpandedAccessSlotsMutation, {
        refetchQueries: expandedAccessSlotsQuery.definitions
            .map(
                (definition) =>
                    (definition as ExecutableDefinitionNode).name?.value || '',
            )
            .filter((name) => name !== ''),
    });

    const updateExpandedAccessSlots = React.useCallback(
        async (
            date: string,
            providerId: string,
            availableSlots: readonly string[],
        ): Promise<void> => {
            await update({
                variables: {
                    vetspireLocationId,
                    date,
                    providerId,
                    availableSlots,
                },
            });
        },
        [update, vetspireLocationId],
    );

    const expandedAccessSlots = React.useMemo(() => {
        const slots = data?.expandedAccessSlots ?? [];
        const map: ExpandedAccessSlots = {};

        for (const slot of slots) {
            const { providerId, date } = slot;
            const key = [date, providerId].join('|');

            map[key] = slot;
        }

        return map;
    }, [data?.expandedAccessSlots]);

    return {
        expandedAccessSlots,
        updateExpandedAccessSlots,
        loading: loading || updating,
    };
}
