import * as React from 'react';
import classnames from 'classnames';
import { DoNotDisturb, OpenInNew } from '@mui/icons-material';

import { useTranslate } from '@bondvet/web-app-i18n/util';
import { LocationConversationPet } from '@bondvet/types/textMessaging';

import { vetspireContext } from 'context/VetspireContext';

import TextingTooltip from '../TextingTooltip';
import { ClientWithPatients } from '../../api';

import styles from './TopPanel.module.scss';

type TopPanelProps = {
    selectedClient: ClientWithPatients;
    recent: boolean;
    unread: boolean;
    markAsReadOrUnread: (unread: boolean) => void;
    updatingConversationUnreadStatus: boolean;
};

type PetsState = {
    visible: React.ReactElement[];
    collapsed: React.ReactElement[];
};

export default function TopPanel({
    selectedClient,
    recent,
    unread,
    markAsReadOrUnread,
    updatingConversationUnreadStatus,
}: TopPanelProps): React.ReactElement {
    const translate = useTranslate();
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const contextRef = React.useRef<CanvasRenderingContext2D | null>(null);
    const [pets, setPets] = React.useState<PetsState>({
        visible: [],
        collapsed: [],
    });

    const getPatientStatusIcon = React.useCallback(
        (isDeceased: boolean, isActive: boolean) => {
            switch (true) {
                case isDeceased:
                    return <span className={styles.deceasedIcon}>D</span>;
                case !isActive:
                    return <DoNotDisturb />;
                default:
                    return <OpenInNew />;
            }
        },
        [],
    );

    const getPatientStatusTooltip = React.useCallback(
        (isDeceased: boolean, isActive: boolean) => {
            switch (true) {
                case isDeceased:
                    return (
                        <span className={styles.tooltip}>
                            {translate(
                                'vetspireExtension.texting.conversationPane.deceased',
                            )}
                            <span className={styles.deceasedIcon}>D</span>
                        </span>
                    );
                case !isActive:
                    return (
                        <span className={styles.tooltip}>
                            {translate(
                                'vetspireExtension.texting.conversationPane.inactive',
                            )}
                            <DoNotDisturb />
                        </span>
                    );
                default:
                    return null;
            }
        },
        [translate],
    );

    const { protocol, hostName } = React.useContext(vetspireContext);
    const getPatientUrl = React.useCallback(
        (patientId: string) => {
            return `${protocol}//${hostName}/clients/${selectedClient.id}/patients/${patientId}`;
        },
        [hostName, protocol, selectedClient.id],
    );

    const clientUrl = React.useMemo(() => {
        return `${protocol}//${hostName}/clients/${selectedClient.id}`;
    }, [hostName, protocol, selectedClient.id]);

    const render = React.useCallback(
        (patient: LocationConversationPet) => {
            return (
                <TextingTooltip
                    key={patient.id}
                    placement="top"
                    title={getPatientStatusTooltip(
                        patient.deceased,
                        !patient.inactive,
                    )}
                >
                    <a
                        className={classnames(styles.pet, {
                            [styles.inactive]:
                                patient.inactive || patient.deceased,
                        })}
                        href={getPatientUrl(patient.id)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {patient.name}
                        {getPatientStatusIcon(
                            patient.deceased,
                            !patient.inactive,
                        )}
                    </a>
                </TextingTooltip>
            );
        },
        [getPatientStatusIcon, getPatientStatusTooltip, getPatientUrl],
    );

    const sortedPets = React.useMemo(() => {
        return (
            selectedClient.pets?.toSorted((a, b) => {
                if (!a.inactive && !a.deceased && (b.inactive || b.deceased)) {
                    return -1;
                }
                if (!b.inactive && !b.deceased && (a.inactive || a.deceased)) {
                    return 1;
                }
                return a.name.localeCompare(b.name);
            }) ?? []
        );
    }, [selectedClient.pets]);

    React.useEffect(() => {
        if (sortedPets.length === 0) {
            return () => {};
        }
        if (!canvasRef.current) {
            canvasRef.current = document.createElement('canvas');
        }
        if (!contextRef.current) {
            contextRef.current = canvasRef.current.getContext('2d');
        }

        const getItemWidth = (petName: string): number => {
            // re-use canvas object for better performance
            const context = contextRef.current;
            if (!context) {
                console.error("Canvas context couldn't be initialized.");
                return 150;
            }
            context.font = '12px Arial';
            const metrics = context.measureText(petName);
            // add 60px to the width because of icon padding gap etc.
            return metrics.width + 60;
        };

        const updateItems = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth - 110;
                let totalWidth = 0;
                let idx = 0;

                for (const pet of sortedPets) {
                    const itemWidth = getItemWidth(pet.name);
                    if (totalWidth + itemWidth < containerWidth) {
                        totalWidth += itemWidth;
                        idx++;
                    } else {
                        break;
                    }
                }

                setPets({
                    visible: sortedPets.slice(0, idx).map((patient) => {
                        return render(patient);
                    }),
                    collapsed: sortedPets.slice(idx).map((patient) => {
                        return render(patient);
                    }),
                });
            }
        };

        window.addEventListener('resize', updateItems);
        updateItems();

        return () => {
            window.removeEventListener('resize', updateItems);
        };
    }, [render, sortedPets]);

    const dropdownRef = React.useRef<HTMLDivElement>(null);

    const toggleDropdown = React.useCallback(() => {
        setDropdownOpen((prevState) => !prevState);
    }, []);

    const handleClickOutside = React.useCallback((event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setDropdownOpen(false);
        }
    }, []);

    React.useEffect(() => {
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen, handleClickOutside]);

    const handleClickMarkAsReadOrUnread = React.useCallback(() => {
        const newUnreadStatus = !unread;

        markAsReadOrUnread(newUnreadStatus);
    }, [unread, markAsReadOrUnread]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <a
                    className={styles.name}
                    href={clientUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    {`${selectedClient.givenName} ${selectedClient.familyName}`}
                </a>
                <div className={styles.phone}>
                    {selectedClient.preferredPhoneNumber}
                </div>
                <div className={styles.petsWrapper} ref={containerRef}>
                    {pets.visible}
                    {pets.collapsed.length !== 0 && (
                        <div
                            className={styles.petDropdownWrapper}
                            ref={dropdownRef}
                        >
                            <button
                                onClick={() => toggleDropdown()}
                                className={styles.moreButton}
                            >
                                +{pets.collapsed.length} more
                            </button>
                            {dropdownOpen && (
                                <div className={styles.petDropdown}>
                                    {pets.collapsed}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {recent && (
                <button
                    className={classnames(styles.unreadButton, {
                        [styles.readButton]: !unread,
                    })}
                    onClick={handleClickMarkAsReadOrUnread}
                    disabled={updatingConversationUnreadStatus}
                >
                    {unread
                        ? translate(
                              'vetspireExtension.texting.conversationPane.markRead',
                          )
                        : translate(
                              'vetspireExtension.texting.conversationPane.markUnread',
                          )}
                </button>
            )}
        </div>
    );
}
