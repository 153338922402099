import * as React from 'react';
import { CreditCard } from 'api/types';
import classnames from 'classnames';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslate } from '@bondvet/web-app-i18n/util';
import type {
    GraphQLGiftCardWithDetails,
    ChargeOrderArguments,
    ChargeOrderResult,
} from '@bondvet/types/giftCards';
import { Order } from 'api/clients/queries';
import { ReactComponent as GiftCardIcon } from 'assets/giftcard.svg';
import { formatAmountAsUSD } from 'components/Credits/util';
import useCreditCardsMutation from 'hooks/useCreditCardsMutation';
import useGiftCardsMutation from 'hooks/useGiftCardsMutation';
import useLocationId from 'hooks/useLocationId';
import {
    chargeInvoice as chargeInvoiceMutation,
    ChargeInvoiceResult,
    ChargeInvoiceVariables,
    chargeOrder as chargeOrderMutation,
} from 'api/clients/mutations';

import Card from '../Card';
import styles from './ChargeGiftCardDialog.module.scss';

type ChargeInvoiceProps = {
    clientId: string;
    giftCards: ReadonlyArray<GraphQLGiftCardWithDetails>;
    refreshData: () => Promise<void>;
    defaultCard: CreditCard | null;
    onClose: () => void;
    invoice: Order;
};

type FormState = {
    [key: string]: {
        selected: boolean;
        amount: string;
    };
};

type ErrorState = {
    globalGiftCards: string;
    giftCards: { [key: string]: string };
};

type SelectedGiftCard = {
    id: string;
    last3?: string;
    amount: string;
};

type GiftCardChargeErrorState = {
    id: string;
    last3?: string;
} | null;

export default function ChargeGiftCardDialog({
    clientId,
    giftCards,
    refreshData,
    defaultCard,
    onClose,
    invoice,
}: ChargeInvoiceProps): React.ReactElement {
    const translate = useTranslate();
    const vetspireLocationId = useLocationId();

    const [step, setStep] = React.useState<'payment' | 'success'>('payment');
    const [formValues, setFormValues] = React.useState<FormState>({});

    const [loading, setLoading] = React.useState(false);
    const [chargeError, setChargeError] =
        React.useState<GiftCardChargeErrorState>(null);
    const [creditCardError, setCreditCardError] = React.useState<
        string | null | undefined
    >(null);
    const [chargeOrder] = useGiftCardsMutation<
        ChargeOrderResult,
        ChargeOrderArguments
    >(chargeOrderMutation);

    const [runChargeInvoiceMutation] = useCreditCardsMutation<
        ChargeInvoiceResult,
        ChargeInvoiceVariables
    >(chargeInvoiceMutation);

    const [fullAmount, setFullAmount] = React.useState(invoice.dueAmount / 100);

    const openAmount = React.useMemo(() => {
        return (
            fullAmount -
            Object.values(formValues).reduce((acc, { amount, selected }) => {
                if (amount === 'NaN') {
                    return acc;
                }
                return acc + (selected ? parseFloat(amount ?? 0) : 0);
            }, 0)
        ).toFixed(2);
    }, [formValues, fullAmount]);

    const validationResult = React.useMemo(() => {
        const result: Partial<ErrorState> = {};
        if (parseFloat(openAmount) < 0) {
            result.globalGiftCards =
                'Gift card amount is too high. Total gift card amount must be less than or equal to the amount due.';
        }

        const invalidGiftCards = Object.keys(formValues).reduce(
            (acc, cardId) => {
                const { selected, amount } = formValues[cardId];

                if (selected) {
                    const cardWithDetails = giftCards.find(
                        (gc) => gc._id === cardId,
                    );
                    if ((cardWithDetails?.balance ?? 0) < parseFloat(amount)) {
                        return {
                            ...acc,
                            [cardId]:
                                'Amount entered cannot be higher than the balance on the gift card.',
                        };
                    }
                }

                return { ...acc };
            },
            {},
        );

        if (Object.keys(invalidGiftCards).length !== 0) {
            result.giftCards = invalidGiftCards;
        }

        return Object.keys(result).length !== 0 ? result : null;
    }, [formValues, giftCards, openAmount]);

    const handleCheckGiftCard = React.useCallback(
        (giftCard: Partial<GraphQLGiftCardWithDetails>, selected: boolean) => {
            let amount: string;
            if (selected) {
                amount = Math.max(
                    Math.min(giftCard.balance ?? 0, parseFloat(openAmount)),
                    0,
                ).toFixed(2);
            } else {
                amount = '0.00';
            }
            setFormValues((values) => ({
                ...values,
                [giftCard._id ?? 'INVALID']: {
                    selected,
                    amount,
                },
            }));
        },
        [openAmount],
    );

    const handleChangeAmount = React.useCallback(
        (giftCardId: string, amount: string) => {
            setFormValues((values) => ({
                ...values,
                [giftCardId]: {
                    selected: true,
                    amount,
                },
            }));
        },
        [],
    );

    const handleSubmit = React.useCallback(async () => {
        setChargeError(null);
        setCreditCardError(null);
        if (!validationResult) {
            setLoading(true);
            const selectedGiftCards: SelectedGiftCard[] = Object.keys(
                formValues,
            ).reduce<SelectedGiftCard[]>((acc, cardId) => {
                const { selected, amount } = formValues[cardId];
                const card = giftCards.find(({ _id }) => cardId === _id);

                if (selected) {
                    return [
                        ...acc,
                        {
                            id: cardId,
                            last3: card?.last3,
                            amount,
                        },
                    ];
                }

                return acc;
            }, []);
            let chargingError;
            const successfulCharges = [];
            for (const giftCard of selectedGiftCards) {
                if (!chargingError) {
                    try {
                        const { data } = await chargeOrder({
                            variables: {
                                vetspireLocationId,
                                vetspireClientId: clientId,
                                cardId: giftCard.id,
                                amount: parseFloat(giftCard.amount),
                                orderId: invoice.id,
                            },
                        });

                        if (!data?.chargeOrder?.success) {
                            chargingError = {
                                id: giftCard.id,
                                last3: giftCard.last3,
                            };
                        } else {
                            successfulCharges.push({
                                id: giftCard.id,
                                amount: parseFloat(giftCard.amount),
                            });
                        }
                    } catch (error) {
                        chargingError = {
                            id: giftCard.id,
                            last3: giftCard.last3,
                        };
                    }
                } else {
                    break;
                }
            }

            if (chargingError) {
                await refreshData();
                successfulCharges.forEach((successfulCharge) => {
                    handleCheckGiftCard({ _id: successfulCharge.id }, false);
                    setFullAmount((prev) => prev - successfulCharge.amount);
                });
                setChargeError(chargingError);
            } else if (parseFloat(openAmount) === 0) {
                setStep('success');
            } else {
                try {
                    const { data } = await runChargeInvoiceMutation({
                        variables: { invoiceId: invoice.id },
                    });

                    if (!data?.chargeInvoice?.success) {
                        await refreshData();
                        successfulCharges.forEach((successfulCharge) => {
                            handleCheckGiftCard(
                                { _id: successfulCharge.id },
                                false,
                            );
                            setFullAmount(
                                (prev) => prev - successfulCharge.amount,
                            );
                        });
                        setCreditCardError(data?.chargeInvoice?.error);
                    } else {
                        setStep('success');
                    }
                } catch (error) {
                    await refreshData();
                    successfulCharges.forEach((successfulCharge) => {
                        handleCheckGiftCard(
                            { _id: successfulCharge.id },
                            false,
                        );
                        setFullAmount((prev) => prev - successfulCharge.amount);
                    });
                    setCreditCardError((error as Error)?.message);
                }
            }
            setLoading(false);
        }
    }, [
        validationResult,
        formValues,
        openAmount,
        giftCards,
        chargeOrder,
        vetspireLocationId,
        clientId,
        invoice.id,
        refreshData,
        handleCheckGiftCard,
        runChargeInvoiceMutation,
    ]);

    const buttonText = React.useMemo(() => {
        if (loading) {
            return <CircularProgress size={20} className={styles.loading} />;
        }

        if (!defaultCard) {
            return (
                <>
                    <GiftCardIcon />
                    <span>
                        {translate('vetspireExtension.chargeGiftCard.charge')}
                    </span>
                </>
            );
        }

        // no gift card selected
        if (!Object.values(formValues).some(({ selected }) => selected)) {
            return (
                <>
                    <CreditCardIcon />
                    <span>
                        {translate(
                            'vetspireExtension.chargeGiftCard.chargeDefault',
                        )}
                    </span>
                </>
            );
        }

        // charge only gift card(s)
        if (parseFloat(openAmount) === 0) {
            return (
                <>
                    <GiftCardIcon />
                    <span>
                        {translate('vetspireExtension.chargeGiftCard.charge')}
                    </span>
                </>
            );
        }

        // charge both
        return (
            <>
                <GiftCardIcon />
                <CreditCardIcon />
                <span>
                    {translate('vetspireExtension.chargeGiftCard.chargeBoth')}
                </span>
            </>
        );
    }, [defaultCard, formValues, loading, openAmount, translate]);

    if (step === 'success') {
        return (
            <Dialog open>
                <DialogTitle className={styles.title}>
                    <IconButton onClick={onClose} className={styles.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={styles.content}>
                    <p className={styles.success}>
                        <CheckIcon />
                        Payment Successful
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            refreshData();
                            onClose();
                        }}
                        className={styles.close}
                    >
                        {translate('vetspireExtension.chargeGiftCard.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Dialog open>
            <DialogTitle className={styles.title}>
                {translate('vetspireExtension.chargeGiftCard.title', {
                    amount: (
                        <span className={styles.bold}>
                            {formatAmountAsUSD(fullAmount * 100)}
                        </span>
                    ),
                })}
                <IconButton onClick={onClose} className={styles.closeIcon}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.content}>
                <div
                    className={classnames(styles.section, {
                        [styles.errorBorder]:
                            !!validationResult?.globalGiftCards,
                    })}
                >
                    <div>
                        <div className={styles.giftCardsTitle}>
                            <GiftCardIcon />
                            <h4>
                                {translate(
                                    'vetspireExtension.chargeGiftCard.giftCards.title',
                                )}
                            </h4>
                        </div>
                        <p>
                            {translate(
                                'vetspireExtension.chargeGiftCard.giftCards.info',
                            )}
                        </p>
                    </div>
                    <div>
                        {giftCards.map((giftCard) => (
                            <div
                                key={giftCard._id}
                                className={classnames(styles.giftCardWrapper, {
                                    [styles.selected]:
                                        formValues[giftCard._id]?.selected,
                                    [styles.errorBorder]:
                                        !!validationResult?.giftCards?.[
                                            giftCard._id
                                        ],
                                })}
                            >
                                <div className={styles.giftCard}>
                                    <Checkbox
                                        checked={
                                            formValues[giftCard._id]
                                                ?.selected ?? false
                                        }
                                        onChange={(e, checked) =>
                                            handleCheckGiftCard(
                                                giftCard,
                                                checked,
                                            )
                                        }
                                    />
                                    <GiftCardIcon />
                                    <span>****{giftCard.last3}</span>
                                    <div className={styles.balanceWrapper}>
                                        <span className={styles.balance}>
                                            {formatAmountAsUSD(
                                                giftCard.balance * 100,
                                            )}
                                        </span>
                                    </div>
                                    <TextField
                                        type="number"
                                        value={
                                            formValues[giftCard._id]?.amount ??
                                            0
                                        }
                                        disabled={
                                            !formValues[giftCard._id]?.selected
                                        }
                                        onChange={(e) =>
                                            handleChangeAmount(
                                                giftCard._id,
                                                parseFloat(
                                                    e.target.value,
                                                ).toFixed(2),
                                            )
                                        }
                                        onWheel={(e) =>
                                            (
                                                e.target as HTMLInputElement
                                            ).blur()
                                        }
                                        inputProps={{
                                            min: 0,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    $
                                                </InputAdornment>
                                            ),
                                            classes: {
                                                root: styles.input,
                                            },
                                        }}
                                    />
                                </div>
                                {validationResult?.giftCards?.[
                                    giftCard._id
                                ] && (
                                    <p className={styles.error}>
                                        {
                                            validationResult?.giftCards?.[
                                                giftCard._id
                                            ]
                                        }
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                    {validationResult?.globalGiftCards && (
                        <p className={styles.error}>
                            {validationResult?.globalGiftCards}
                        </p>
                    )}
                </div>
                {defaultCard && (
                    <div className={styles.section}>
                        <h4 className={styles.defaultCardTitle}>
                            <CreditCardIcon />
                            {translate(
                                'vetspireExtension.chargeGiftCard.defaultCard.title',
                            )}
                        </h4>
                        <p>
                            {translate(
                                'vetspireExtension.chargeGiftCard.defaultCard.info',
                            )}
                        </p>
                        <div className={styles.defaultCard}>
                            <Card card={defaultCard} />
                            <span>
                                $
                                <span
                                    className={classnames(styles.balance, {
                                        [styles.empty]: !Math.max(
                                            parseFloat(openAmount),
                                            0,
                                        ),
                                    })}
                                >
                                    {Math.max(
                                        parseFloat(openAmount),
                                        0,
                                    ).toFixed(2)}
                                </span>
                            </span>
                        </div>
                    </div>
                )}
                {defaultCard && (
                    <p className={styles.chargeCardDisclaimer}>
                        {translate(
                            !Math.max(parseFloat(openAmount), 0)
                                ? 'vetspireExtension.chargeGiftCard.defaultCard.noRemainingAmount'
                                : 'vetspireExtension.chargeGiftCard.defaultCard.remainingAmount',
                            {
                                amount: (
                                    <span className={styles.bold}>
                                        ${openAmount}
                                    </span>
                                ),
                                last4: defaultCard.last4,
                            },
                        )}
                    </p>
                )}
                {chargeError && (
                    <p className={styles.error}>
                        {translate('vetspireExtension.chargeGiftCard.error', {
                            last3: (
                                <span className={styles.bold}>
                                    {chargeError.last3}
                                </span>
                            ),
                        })}
                    </p>
                )}
                {creditCardError && (
                    <p className={styles.error}>{creditCardError}</p>
                )}
                <DialogActions>
                    <Button type="button" onClick={onClose}>
                        {translate('vetspireExtension.chargeGiftCard.cancel')}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        className={styles.submit}
                        disabled={
                            loading ||
                            !!validationResult ||
                            (!defaultCard && parseFloat(openAmount) !== 0)
                        }
                    >
                        {buttonText}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
