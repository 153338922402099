import type { Provider } from 'hooks/useProviders';

// month format to be used with date-fns' parse/format functions
export const MONTH_FORMAT = 'yyyy-MM';

// date format to be used with date-fns' parse/format functions
export const DATE_FORMAT = 'yyyy-MM-dd';

export const DISPLAY_DATE_FORMAT = 'LLL d, yyyy';

export const DISPLAY_TIME_FORMAT = 'h:mm a';

export const DATA_TIME_FORMAT = 'HH:mm';

export type ProviderSlot = {
    label: string;
    time: string;
    // providerId and ISO time string, joined by |
    key: string;
    availableForExtendedAccess: boolean;
};

export type ProviderSlotOption = ProviderSlot & {
    canBeSelected: boolean;
};

export type ProviderAndSlots = Pick<Provider, 'id' | 'name'> & {
    slots: readonly ProviderSlot[];
    numberOfAvailableForExtendedAccess: number;
};

export type DayWithAdditionalCapacitySlots = {
    // date in format yyyy-MM-dd
    date: string;
    // day of the month
    label: string;
    providers: readonly ProviderAndSlots[];
    isToday: boolean;
    isFuture: boolean;
};

export type Capacities = Record<string, DayWithAdditionalCapacitySlots>;

export type Appointment = {
    id: string;
    start: string;
    patient: null | { name: string; client: null | { name: string } };
    providerId: string;
};

// the key is the providerId and the ISO time string of the appt. start,
// joined with a |
export type BookedAppointments = Record<string, readonly Appointment[]>;

export type UpdateExpandedAccessSlots = (
    date: string,
    providerId: string,
    availableSlots: readonly string[],
) => Promise<void>;
